"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CREDIT_APPLICATION_FORM = void 0;
const CREDIT_APPLICATION_FORM = exports.CREDIT_APPLICATION_FORM = [{
  fields: [{
    input: 'creditApplication'
  }]
}];