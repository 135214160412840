"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformLeadTypesToSuggestionList = exports.getVisibleTabs = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _schema = require("../config/schema/schema.tabs");
var _constants = require("../tools/constants");
var _schema2 = require("../config/schema/tabs/schema.finance");
var _schemaCredit = require("../config/schema/tabs/schema.credit.application");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const transformLeadTypesToSuggestionList = (allLeadTypes, selectedLeadSource, t) => {
  if (!allLeadTypes.length) return [];
  const leadTypes = allLeadTypes.map(d => ({
    id: d === null || d === void 0 ? void 0 : d.id,
    value: (0, _commonsUtils.nullOrString)(d === null || d === void 0 ? void 0 : d.id),
    label: d === null || d === void 0 ? void 0 : d.type
  })).sort((a, b) => {
    var _a$label;
    return (a === null || a === void 0 || (_a$label = a.label) === null || _a$label === void 0 ? void 0 : _a$label.localeCompare(b === null || b === void 0 ? void 0 : b.label)) || -1;
  });
  let suggestionList = [];
  let otherList = [];
  const suggestionTypesByLeadSource = _constants.LEAD_TYPES_SUGGESTION_GROUPING === null || _constants.LEAD_TYPES_SUGGESTION_GROUPING === void 0 ? void 0 : _constants.LEAD_TYPES_SUGGESTION_GROUPING[selectedLeadSource];
  if (!suggestionTypesByLeadSource) return leadTypes;
  leadTypes.forEach(type => {
    if (suggestionTypesByLeadSource.includes(type.id)) {
      suggestionList.push(type);
    } else {
      otherList.push(type);
    }
  });
  if (!suggestionList.length) return otherList;
  return [{
    label: t('leads.createView.createLead.createForm.SUGGESTIONS'),
    options: suggestionList
  }, {
    label: t('leads.createView.createLead.createForm.OTHER_TYPES'),
    options: otherList
  }];
};
exports.transformLeadTypesToSuggestionList = transformLeadTypesToSuggestionList;
const getVisibleTabs = (lead, rights, disabled, hasShowroom) => {
  let newTabs = [..._schema.CONST_TABS];
  if (lead !== null && lead !== void 0 && lead.tracking) {
    const trackingTabIdx = newTabs.findIndex(t => t.id === 'tracking');
    newTabs.splice(trackingTabIdx, 1, {
      title: 'leads.updateView.trackingForm.TAB_LABEL',
      id: 'tracking',
      body: [{
        legend: 'leads.updateView.trackingForm.TRACKING_INFOS',
        fields: [{
          input: 'tracking'
        }]
      }]
    });
  }
  if (!_constants.LEAD_SERVICE_CATEGORIES.includes(lead === null || lead === void 0 ? void 0 : lead.leadCategoryId)) {
    const serviceTabIdx = newTabs.findIndex(t => t.id === 'service');
    newTabs.splice(serviceTabIdx, 1);
  }
  if (!rights.ManageDocumentLead) {
    const documentsTabIdx = newTabs.findIndex(t => t.id === 'documents');
    newTabs.splice(documentsTabIdx, 1);
  }
  if (!rights.ManageAntiSpam) {
    const antiSpamTabIdx = newTabs.findIndex(t => t.id === 'antiSpam');
    newTabs.splice(antiSpamTabIdx, 1);
  }

  // Finance tab
  const financingTabIdx = newTabs.findIndex(t => t.id === 'financeInfo');
  if (rights.ViewContactFinancing) {
    newTabs.splice(financingTabIdx, 1, {
      id: 'financeInfo',
      title: 'leads.updateView.financeForm.TAB_LABEL',
      body: hasShowroom ? _schemaCredit.CREDIT_APPLICATION_FORM : _schema2.FINANCE_FORM
    });
  } else {
    // remove the tab
    newTabs.splice(financingTabIdx, 1);
  }

  // Compute disable state for each field
  newTabs = newTabs.map(tab => {
    if (tab.body) {
      const updatedBody = tab.body.map(bodyItem => {
        if (bodyItem !== null && bodyItem !== void 0 && bodyItem.fields) {
          return _objectSpread(_objectSpread({}, bodyItem), {}, {
            fields: bodyItem.fields.map(field => _objectSpread(_objectSpread({}, field), {}, {
              disabled
            }))
          });
        }
        return bodyItem;
      });
      return _objectSpread(_objectSpread({}, tab), {}, {
        body: updatedBody
      });
    } else return tab;
  });
  return newTabs;
};
exports.getVisibleTabs = getVisibleTabs;