"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fr = void 0;
const fr = exports.fr = {
  authentication: {
    ERROR: 'Votre authentification dans le système a expiré. Veuillez vous reconnecter.',
    IAT_IS_IN_FUTURE: "Le service d'authentification ne peut pas émettre de jeton valide car il semble que l'horloge de votre système ne soit pas automatiquement réglée.",
    TOKEN_ISSUE_TIME: "Votre jeton a été émis le <0></0> mais l'heure de votre système est différente: <1></1>."
  },
  commons: {
    yes: 'Oui',
    no: 'Non',
    and: 'et',
    or: 'ou',
    NA: 'N/D',
    INACTIVE: 'Inactif',
    DEALER: 'Concessionnaire',
    ERROR: 'Erreur',
    actions: {
      ADD: 'Ajouter',
      APPLY: 'Appliquer',
      ARCHIVE: 'Archiver',
      BACK: 'Retour',
      BACK_TO_SUGGESTIONS: 'Retour aux suggestions',
      BUY: 'Acheter',
      CANCEL: 'Annuler',
      CLONE: 'Dupliquer',
      CLOSE: 'Fermer',
      CREATE: 'Créer',
      CONFIRM: 'Confirmer',
      CURRENTLY_ACTIVE: 'Actuellement actif',
      DELETE: 'Supprimer',
      DESELECT: 'Désélectionner',
      DISPLAY: 'Afficher',
      DUPLICATE: 'Dupliquer',
      EDIT_CONTACT: 'Ouvrir le contact',
      EDIT_CONTACT_NEWTAB: 'Ouvrir le contact dans un nouvel onglet',
      EDIT_LEAD: "Ouvrir l'opportunité",
      EDIT_DEAL: 'Ouvrir la transaction',
      EDIT_NEWTAB: 'Ouvrir dans un nouvel onglet',
      EDIT: 'Modifier',
      ENTER: 'Entrer',
      FIND_DUPLICATE: 'Rechercher des doublons',
      HIDE: 'Cacher',
      IMPORT: 'Importer',
      LOAD: 'Charger',
      MERGE: 'Fusionner',
      MINIMIZE: 'Minimiser',
      NEXT: 'Suivant',
      OPEN: 'Ouvrir',
      OPEN_IN_NEW_TAB: 'Ouvrir dans un nouvel onglet',
      PASTE: 'Coller',
      PRINT: 'Imprimer',
      REFINE: 'Sélectionner',
      REFRESH: 'Actualiser',
      REMOVE: 'Enlever',
      REPLY: 'Répondre',
      RESTORE: 'Restaurer',
      RETRY: 'Réessayer',
      RESET: 'Réinitialiser',
      RUN: 'Executer',
      SAVE: 'Sauvegarder',
      SEARCH: 'Rechercher',
      SELECT_ALL_ELEMENT: 'Sélectionner tous les {{objectType}}',
      SELECT_ALL: 'Sélectionner tous',
      SELECT: 'Sélectionner',
      SEND: 'Envoyer',
      SHOW: 'Afficher',
      SYNC: 'Synchroniser',
      TRIGGER: 'Déclencher',
      UNSELECT_ALL: 'Désélectionner tous',
      UPLOAD: 'Téléverser'
    },
    actionbar: {
      SKIP_TITLE: "Passer à la barre d'action",
      BAR_TITLE: "Barre d'action"
    },
    errors: {
      401: {
        title: 'Accès non authentifié',
        description: 'Vous devez être connecté pour accéder à cette page.'
      },
      403: {
        title: 'Accès non autorisé',
        description: "Vous n'avez probablement pas les droits pour accéder à cette page. Vous pouvez contacter l'équipe de Support pour obtenir plus d'informations."
      },
      404: {
        title: 'Page introuvable',
        description: 'La page que vous recherchez est introuvable.'
      },
      TOO_MANY_REQUESTS: {
        title: 'Trop de requêtes',
        description: "Nous avons détecté un usage suspicieux du CRM (dépassement de la limite de requêtes permise par heure), SVP contactez l'équipe de support."
      },
      UNEXPECTED: {
        title: "Il semble qu'il y ait une erreur inattendue dans l'application.",
        description: "Veuillez contacter un administrateur et/ou l'équipe d'assistance en leur fournissant les détails de l'erreur.",
        CTA: 'Essayez à nouveau',
        view_details: "Afficher les détails de l'erreur"
      },
      GO_TO_HOMEPAGE: "Aller à la page d'accueil",
      FAILURE: 'Échec'
    },
    language: {
      ENGLISH: 'Anglais',
      FRENCH: 'Français'
    },
    crud: {
      ENTRY: 'entrée',
      ENTRIES: 'entrées',
      ADDNEW: 'Ajouter une entrée',
      SHOWARCHIVED: 'Afficher les entrées archivées',
      COLUMNS_TOGGLE_INSTRUCTIONS: 'Sélectionner les colonnes à afficher ou réordonner les par glisser/déposer ',
      LOADING: 'Chargement',
      NOENTRY: "Pas d'entrée",
      ACTIONS: 'Actions',
      CREATED: 'Nouvelle entrée créée',
      UPDATED: 'Entrée modifiée',
      DELETED: 'Entrée supprimée'
    },
    rightsWarning: {
      RIGHT_REQUIRED: "Vous n'avez pas le droit requis: {{right}}",
      Admin: 'Seuls les administrateurs sont autorisés à accéder à cette section.',
      Beta: 'Cette fonctionnalité est restreinte aux utilisateurs Beta.',
      ContactAccess: "Vous n'êtes pas autorisé à accéder aux détails d'un contact.",
      CrmDealViewDetail: "Vous n'êtes pas autorisé à consulter les détails des transactions.",
      CrmDeleteActivity: "Vous n'êtes pas autorisé à supprimer des activités.",
      CrmImportContactListAccess: "Vous n'êtes pas autorisé à importer une liste de contacts.",
      CrmProAccess: "Vous n'êtes pas autorisé à accéder à la section CRM.",
      CrmProAdministrationSectionAccess: "Vous n'êtes pas autorisé à accéder aux outils d'administration.",
      CrmProEditEmailTemplates: "Vous n'êtes pas autorisé à gérer les gabarits de courriel.",
      CrmProModifyLeadDeliveredDate: "Vous n'êtes pas autorisé à modifier la date de vente ou de livraison des opportunités.",
      CrmProModifyLeadSoldDate: "Vous n'êtes pas autorisé à modifier la date de vente ou de livraison des opportunités.",
      CrmSmsFeatureUse: "Vous n'avez pas l'autorisation d'envoyer des SMS.",
      CrmSmsNumbersManagement: "Vous n'êtes pas autorisé à configurer les numéros SMS.",
      DeleteContact: "Vous n'êtes pas autorisé à supprimer des contacts.",
      DeleteLead: "Vous n'êtes pas autorisé à supprimer des opportunités.",
      DeskingCanUpdateDealStatus: "Vous n'êtes pas autorisé à modifier le status des transactions.",
      EditBdcConfiguration: "Vous n'êtes pas autorisé à configurer les heures d'ouverture et fériés des concessions.",
      EditCampaign: "Vous n'êtes pas autorisé à consulter les détails des campagnes.",
      EditLead: "Vous n'êtes pas autorisé à modifier des opportunités.",
      EditRestrictedLeadStatus: "Vous n'êtes pas autorisé à modifier une opportunité avec un status restreint.",
      IvrAudioPlayer: "Un enregistrement pour cet appel est disponible mais vous n'avez pas l'autorisation requise pour l'écouter.",
      IvrEnabled: "Vous n'êtes pas autorisé à configurer les numéros PhoneTrack.",
      IvrInbound: "Vous n'êtes pas autorisé à recevoir des appels.",
      IvrOutbound: "Vous n'êtes pas autorisé à passer des appels.",
      leadHasDeskingQuote: 'Les dates de vente et de livraison peuvent être changées à partir de la Soumission.',
      LeadStatusConfigManagement: "Vous n'êtes pas autorisé à configurer les status d'opportunités.",
      ManageAntiSpam: "Vous n'êtes pas autorisé à accéder à cette section.",
      ManageDocumentLead: "Vous n'êtes pas autorisé à accéder à cette section.",
      ModuleLeadEnabled: "Vous n'êtes pas autorisé à accéder aux opportunités.",
      ReadAllFollowEvent: "Vous n'êtes pas autorisé à consulter les tâches des autres.",
      ReadFollowLead: "Vous n'êtes pas autorisé à voir les opportunités des autres.",
      ReAssignLead: "Vous n'êtes pas autorisé à réattribuer des opportunités.",
      ReportingSharedConfigManagement: "Vous n'êtes pas autorisé à gérer les configurations partagées.",
      ViewLead: "Vous n'êtes pas autorisé à voir le contenu des opportunités.",
      ViewEmail: "Vous n'êtes pas autorisé à voir le contenu des courriels.",
      ViewSms: "Vous n'êtes pas autorisé à lire les messages SMS."
    },
    filters: {
      OPEN_FILTERS: 'Afficher la recherche avancée',
      CLOSE_FILTERS: 'Masquer la recherche avancée',
      RESET_FILTERS: 'Réinitialiser les filtres'
    },
    selectMultiple: {
      label: {
        search: 'Recherche',
        selectbutton: 'Sélect. tout',
        clearbutton: 'Désélect. tout',
        selection: 'sélections',
        allSelected: 'Tous sélectionnés',
        noSelection: 'Aucun',
        anonymous: 'Anonyme',
        selected: 'Sélectionnés',
        available: 'Disponibles'
      }
    },
    validation: {
      required: 'Ce champ est obligatoire',
      tel: 'Veuillez entrer un numéro de téléphone valide',
      email: 'Veuillez entrer un courriel valide',
      samePassword: 'Ce mot de passe est différent du premier mot de passe saisi',
      postalCode: 'Code postal invalide (A1A 1A1 ou 00000)'
    },
    COUNTRY: 'Pays',
    countryCode: {
      CA: 'Canada',
      US: 'USA'
    },
    months: {
      JANUARY: 'Janvier',
      FEBRUARY: 'Février',
      MARCH: 'Mars',
      APRIL: 'Avril',
      MAY: 'Mai',
      JUNE: 'Juin',
      JULY: 'Juillet',
      AUGUST: 'Août',
      SEPTEMBER: 'Septembre',
      OCTOBER: 'Octobre',
      NOVEMBER: 'Novembre',
      DECEMBER: 'Décembre'
    },
    dateRanges: {
      TODAY: "Aujourd'hui",
      YESTERDAY: 'Hier',
      LAST_SEVEN_DAYS: '7 derniers jours',
      LAST_FOURTEEN_DAYS: '14 derniers jours',
      LAST_THIRTY_DAYS: '30 derniers jours',
      LAST_120_DAYS: '120 derniers jours',
      CURRENT_WEEK: 'Semaine courante',
      CURRENT_MONTH: 'Mois courant',
      CURRENT_YEAR: 'Année courante',
      LAST_WEEK: 'Semaine dernière',
      LAST_MONTH: 'Mois précédent',
      LAST_YEAR: 'Année précédente',
      NEXT_SEVEN_DAYS: '7 prochains jours',
      NEXT_FOURTEEN_DAYS: '14 prochains jours',
      NEXT_THIRTY_DAYS: '30 prochains jours',
      NEXT_MONTH: 'Mois prochain',
      NEXT_SIX_MONTHS: '6 prochains mois',
      CUSTOM: 'Personnalisé'
    }
  },
  app: {
    // WELCOME_MESSAGE: 'Bienvenue',
    // NO_ACCESS_APP: "Vous n'avez peut-être pas les droits pour accéder à cette application.",
    // NO_ACCESS_SECTION: "Vous n'avez peut-être pas les droits pour accéder à cette section.",
    // FETCH_DATA: {
    //     0: 'Récupération des données',
    //     1: 'Il est possible que ce soit un peu long...',
    // },
    NEW_MESSAGE: '{{count}} nouveau message',
    NEW_MESSAGES: '{{count}} nouveaux messages',
    // update: {
    //     CTA: 'Mettre à jour',
    //     CTA_long: "Mettre à jour et relancer l'application",
    //     label: "Une nouvelle version ({{version}}) de l'application est disponible",
    // },
    support: {
      HELP_CENTER: "Centre d'aide",
      BY_PHONE_TITLE: 'Support prioritaire',
      BY_PHONE_DESCRIPTION: 'Pour assistance immédiate, contactez notre équipe de support en composant le numéro suivant: 1-833-360-0485',
      BY_TICKET_TITLE: "Centre d'aide",
      BY_TICKET_DESCRIPTION: 'Accéder à votre portail afin de créer un billet pour notre équipe de support.',
      BY_TICKET_CTA: 'Nouveau billet'
    },
    // user: {
    //     SIGNIN: 'Connexion',
    //     SIGNOUT: 'Déconnexion',
    //     GRAVATAR_CTA: 'Ajouter une image de profil avec Gravatar.com.',
    //     GRAVATAR_HELP_CTA: 'En savoir plus',
    //     CHECK_RIGHTS: "Vérification des droits d'accès",
    //     FETCH_DATA: 'Récupération du contenu',
    //     CTA_TOKEN: "Copier l'ID Token",
    //     TOKEN_COPY_SUCCESS: 'ID Token copié',
    //     TOKEN_COPY_ERROR: 'ID Token non-copié',
    //     CTA_CHANGE_ORGANIZATION: "Changer d'organisation",
    //     caracters: '8 ou plus de caractères',
    //     letters: 'Lettres majuscules et minuscules',
    //     numbers: 'Au moins un numéro',
    //     confirm: 'Mot de passe doit être identique',
    //     updatePassword: 'Mettre à jour son mot de passe',
    //     modifyPassword: 'Modifier son mot de passe',
    //     mustHave: 'Le mot de passe doit avoir:',
    //     password: 'Mot de passe',
    //     newPassword: 'Nouveau mot de passe',
    //     confirmPassword: 'Confirmer mot de passe',
    //     CHANGE_PASSWORD_FAIL: 'Erreur mot de passe',
    //     CHANGE_PASSWORD_SUCCESS: 'Le mot de passe à été changé',
    //     PROFILE: 'Profil',
    //     TITLE_MAIN_SETTINGS: 'Réglages principaux',
    //     FULL_NAME: 'Nom complet',
    //     EMAIL: 'Courriel',
    //     SECURITY: 'Sécurité',
    //     PHONE: 'Téléphone',
    //     PHONE_NUMBERS: 'Numéros de téléphone',
    //     NOTIFICATIONS: 'Notifications',
    //     PREFERENCES: 'Préférences',
    // },
    nav: {
      ACTIVITIES: 'Activités',
      ACTIVITY_DASHBOARD: "Tableau d'activités",
      ADMINISTRATION_SHORT: 'ADMIN',
      ADMINISTRATION: "Outils d'administration",
      APPOINTMENTS: 'Rendez-vous',
      AUDIT_DESCRIPTION: 'Consulter les Audit Trails',
      AUDIT: 'Audit Trail',
      BDC_CONFIG: 'Configuration du CDA',
      BRAND_COMPLIANCES: 'Conformités de la marque',
      BUILDER: 'Construire un véhicule',
      BULKINSERTS_DESCRIPTION: 'Configurer les imports en lot',
      BULKINSERTS: 'Imports en lot',
      CALENDAR: 'Calendrier',
      CAMPAIGN_STATS: 'Statistiques',
      CAMPAIGN: 'Gestionnaire de campagnes',
      COLANDER_DESCRIPTION: 'Liste des filtres pour Elasticsearch',
      COLANDER: 'Colander',
      CONTACTS: 'Contacts',
      DASHBOARD: 'Tableau de bord',
      DEAL_CONFIGURATION_DESCRIPTION: 'Configurer les imports de deal',
      DEAL_CONFIGURATION: 'Configuration des imports de deal',
      DEAL_SERVICE_CONFIGURATION: 'Configuration des imports deal et service',
      DEALER_REBATES: 'Rabais',
      DEALERS_DESCRIPTION: 'Gestion du groupe et des concessionnaires',
      DEALERS: 'Concessionnaires',
      DEALS: 'Transactions',
      DELIVERY_DASHBOARD: 'Tableau de livraison',
      DESKING_INVENTORY_REPORT: "Rapport d'inventaire",
      DESKING_QUOTES: 'Soumissions',
      DESKING_SALES_REPORT: 'Rapport de vente',
      DESKING_VEHICLES: 'Recherche de véhicules',
      DESKING: 'Desking',
      DOCUMENTS_SHORT: 'DOCS',
      DOCUMENTS: 'Documents',
      DOWNLOADS_DESCRIPTION: 'Voir les téléchargements',
      DOWNLOADS: 'Centre de téléchargement',
      EMAIL_NOTIFICATIONS_DESCRIPTION: 'Récupérer des informations depuis le SES',
      EMAIL_NOTIFICATIONS: 'Notifications courriel',
      EVENTS_CONFIG: "Configuration d'événements",
      EXPORT_CONFIG_DESCRIPTION: "Configurer les FTP d'export d'inventaire",
      EXPORT_CONFIGURATION: "Export d'inventaire",
      EXPORT_REPORTS: 'Export de rapports',
      EXTDATA_EXPORT: 'External Data Export',
      GENERAL_SETTINGS: 'Paramètres généraux',
      GLOSSARY: 'Glossaire',
      IMPORT_CONFIG_DESCRIPTION: "Configurer les FTP d'import d'inventaire (xmsdaemonic)",
      IMPORT_CONFIGURATION: "Import d'inventaire",
      IMPORT_EXPORT_CONFIGURATION: 'Configuration FTP Import/Export',
      IMPORT_TYPES_DESCRIPTION: "Gestion des types d'import",
      IMPORT_TYPES: "Types d'import",
      IMPORTS_CONFIGURATION: 'Configuration des Imports',
      IMPORTS_DASHBOARD_DESCRIPTION: 'Tableau de bord des imports',
      IMPORTS_DASHBOARD: 'Imports Log',
      IMPORTS_DESCRIPTION: "Gestion des imports d'inventaires",
      IMPORTS_MANAGEMENT: 'Gestion des imports',
      IMPORTS: 'Imports',
      INFO_WEBSITES: 'Info Websites',
      INGRESS_DESCRIPTION: 'Configurer les noms de domaine des concessionnaires',
      INGRESS: 'Ingress',
      INVENTORIES_DESCRIPTION: "Gestion d'inventaires",
      INVENTORY_OPERATIONS: 'Opérations sur les inventaires',
      INVENTORIES: 'Inventaire',
      INVENTORY_LISTING: "Liste d'inventaire",
      INVENTORY_VDP: 'Inventaire VDP',
      INVOICING_SERVICE: 'Support service de facture des véhicules',
      LEAD_EXPORT_CONFIGURATION: 'Export de leads',
      LEAD_EXPORT_DESCRIPTION: "Configurer les FTP d'export de leads",
      LEADS: 'Opportunités',
      NEW_CAR_TRIM_DESCRIPTION: 'Mettre à jour new car trim',
      NEW_CAR_TRIM: 'New Car Trim',
      OEM_MANAGEMENT_DESCRIPTION: "Gérer l'ordre des modèles du catalogue",
      OEM_MANAGEMENT: 'Oem Catalogue Management',
      OPERATIONS_SHORT: 'OPS',
      OPERATIONS: 'Opérations',
      PHONETRACK_LOGS: "Journal d'appels",
      PHONETRACK_NUMBERS: 'Numéros configurés',
      PRIVACY360: 'Confidentialité 360',
      RECAPTCHA: 'Google reCAPTCHA',
      REPORT_GEN: 'Générateur de rapports',
      REPORTING: 'Rapports',
      REPORTS_DESCRIPTION: 'Afficher les rapports de concessionnaires',
      REPORTS: 'Rapports',
      SERVICE_CONFIGURATION_DESCRIPTION: 'Configurer les imports de service',
      SERVICE_CONFIGURATION: 'Configuration des imports de service',
      SETTINGS: 'Réglages',
      SHOWROOM_AND_DESKING_INTEGRATIONS_SETTINGS_DESCRIPTION: "Gestion des réglages d'intégrations",
      SHOWROOM_AND_DESKING_INTEGRATIONS_SETTINGS: "Réglages d'Intégrations",
      SHOWROOM_AND_DESKING_SERVICES: 'DESKING',
      SHOWROOM360: 'Showroom 360',
      SMS_LOGS: 'Journal de SMS',
      SMS_NUMBERS: 'Numéros SMS',
      STYLES_MANAGEMENT: 'Gestion des Styles',
      SUBSCRIPTIONS: 'Portfolio Client',
      SUMMARY_PAGE: 'Page de sommaire',
      SUPER_ADMIN: 'Outils super admin',
      SURVEYS: 'Sondages',
      TASKS: 'Tâches',
      THIRDPARTY_CONFIGURATION: 'Thirdparty Configuration',
      THIRDPARTY_DEALER_DESCRIPTION: 'Configurer les Thirdparty Dealer',
      THIRDPARTY_DEALER: 'Configuration Thirdparty Dealer',
      THIRDPARTY_LEADS_HISTORY_DESCRIPTION: 'Thirdparty Leads History',
      THIRDPARTY_LEADS_HISTORY: 'Thirdparty Leads History',
      THIRDPARTY_PUSH_DESCRIPTION: 'Configurer les Thirdparty push',
      THIRDPARTY_PUSH_HISTORY_DESCRIPTION: 'Configurer les Thirdparty Push History',
      THIRDPARTY_PUSH_HISTORY: 'Thirdparty Push History',
      THIRDPARTY_PUSH: 'Configuration Thirdparty push',
      TOOLS: 'Outils',
      USER_LOOKUP_DESCRIPTION: "Consulter les informations d'un utilisateur",
      USER_LOOKUP: 'Informations utilisateur',
      USERS_DESCRIPTION: 'Gestion des utilisateurs et de leurs droits',
      USERS: 'Utilisateurs',
      VEHICLE_UPDATE_ACTION_DESCRIPTION: 'Mettre à jour tous les véhicules pour un inventaire',
      VEHICLE_UPDATE_ACTION: "Mise à jour d'inventaire en lot",
      VEHICLES_DESCRIPTION: 'Liste des véhicules en inventaire',
      VEHICLES: 'Véhicules',
      VIRTUALTOURS_DESCRIPTION: 'Configurer le Evox ID pour les tours virtuels',
      VIRTUALTOURS: 'Tours virtuels',
      WEBSITES_MANAGEMENT_SHORT: 'WEB',
      WEBSITES_MANAGEMENT: 'Gestion des sites web',
      WEBSITES: 'Site web',
      DUPLICATE_WEBSITES: 'Duplicate Websites',
      PROMO_AUTOMATION: 'Automatisation des promotions',
      PROMO_AUTOMATION_DESCRIPTION: "Gérez l'automatisation des promotions par région et par concessionnaire."
    },
    oauth: {
      TITLE: 'Synchronisation oAuth',
      CLOSE: "Vous pouvez fermer cet onglet ou revenir à la page d'accueil.",
      SYNCING: 'Synchronisation... Ne fermez pas cet onglet.',
      SYNC_SUCCESS: 'Votre boîte courriel a été synchronisée avec succès avec CRM.',
      SYNC_ERROR: "Une erreur s'est produite lors de la configuration de la connexion oAuth.",
      MISSING_PARAMETERS: "La connexion oAuth n'a pas pu être établie car aucun code d'autorisation n'a été fourni."
    }
  },
  apiError: {
    getActivity: "Une erreur s'est produite lors de la récupération des informations de l'activité.",
    getEmailByActivityId: "Une erreur s'est produite lors de la récupération des informations du courriel."
  },
  crm: {
    SUGGESTIONS: 'Suggestions',
    OTHERS: 'Autres',
    users: {
      OWNER: 'Responsable',
      SALESPERSON: 'Vendeur(euse)',
      SALES_MANAGER: 'Directeur(trice) des ventes',
      BUSINESS_MANAGER: 'Directeur(trice) commercial(e)'
    }
  },
  deskingCommon: {
    currency: {
      prefix: '',
      suffix: ' $'
    },
    emptyGarage: {
      add: 'Ajouter',
      buttonLabel: 'Commencer',
      configure: 'Configurer un véhicule',
      new: 'Nouveau',
      newVehicle: 'Inventaire neuf',
      subtitle: 'À quel type de véhicule votre client s’intéresse-t-il?',
      title: 'Votre client n’est associé à aucun véhicule.',
      unlistedVehicle: 'Véhicule non répertorié',
      unlistedVehicleNew: 'Véhicule non répertorié (neuf)',
      unlistedVehicleUsed: 'Véhicule non répertorié (occasion)',
      used: 'Occasion',
      usedVehicle: 'Inventaire d’occasion'
    },
    error: {
      addCommissionAdjustment: "Une erreur est survenue en essayant d'ajouter la commission.",
      addDealJacketForm: "Une erreur est survenue en essayant d'ajouter un nouveau formulaire.",
      allocateEquity: "Une erreur est survenue en essayant d'ajouter l'équité",
      api: "Une erreur est survenue lors d'une tentative de connexion avec le serveur.",
      assignLead: "Une erreur est survenue en essayant d'attribuer l'opportunité.",
      commissionAmountValidation: 'Une erreur est survenue en essayant de valider le montant de la commission.',
      createDeal: 'Une erreur est survenue en essayant de créer le Deal',
      createDMSAccount: 'Une erreur est survenue en essayant de créer un compte DMS au contact.',
      createItem: 'Une erreur est survenue en essayant de créer un item.',
      createQuote: 'Une erreur est survenue en essayant de créer la transaction.',
      deleteCommissionAdjustment: 'Une erreur est survenue en essayant de supprimer la commission.',
      deleteDeal: 'Une erreur est survenue en essayant de supprimer le Deal',
      deleteDealJacketForm: 'Une erreur est survenue en essayant de supprimer un formulaire.',
      deleteDealJacketForms: 'Une erreur est survenue en essayant de supprimer des formulaires.',
      submitDealJacketForms: 'Une erreur est survenue en essayant de soumettre des formulaires.',
      forbidden: "Pas d'accès à Desking.",
      getAccounting: 'Une erreur est survenue en essayant de récupérer les informations de la comptabilité.',
      getCommission: 'Une erreur est survenue en essayant de récupérer les informations de la commission.',
      getContractValidationDecision: 'Une erreur est survenue en essayant de récupérer les informations de décision pour la validation du contract',
      getCreditApplicationDecision: 'Une erreur est survenue en essayant de récupérer les informations de décision pour la demande de crédit',
      getCreditApplicationWidget: "Une erreur est survenue en essayant de récupérer le widget d'application de crédit.",
      getCustomer: 'Une erreur est survenue en essayant de récupérer le client.',
      getDealerInfo: 'Une erreur est survenue en essayant de récupérer les informations du concessionnaire',
      getDealersList: 'Une erreur est survenue en essayant de récupérer la liste des concessionnaires.',
      getFilters: 'Une erreur est survenue en essayant de récupérer les données des filtres.',
      getFNIItems: "Une erreur est survenue en essayant de récupérer une liste d'items de FNI.",
      getForms: 'Une erreur est survenue en essayant de récupérer la liste de formulaires.',
      getGarage: 'Une erreur est survenue en essayant de récupérer les informations du garage.',
      getItems: "Une erreur est survenue en essayant de récupérer une liste d'items.",
      getLeadInfo: "Une erreur est survenue en essayant de récupérer les utilisateurs assignés à l'opportunité.",
      getMatches: 'Une erreur est survenue en essayant de récupérer la liste des matches.',
      getModel: 'Une erreur est survenue en essayant de récupérer les informations du modèle.',
      getModelYears: 'Une erreur est survenue en essayant de récupérer la liste des années disponibles.',
      getPdfFilesList: "Une erreur est survenue en essayant d'obtenir la liste des PDFs disponibles.",
      getXMSCompleteWindowTag: "Une erreur est survenue en essayant d'obtenir la feuille de vitre complète XMS.",
      getQuote: "Une erreur est survenue en essayant de récupérer les informations d'une transaction.",
      getRights: "Une erreur est survenue en essayant de récupérer les droits d'accès.",
      getTradeIn: 'Une erreur est survenue en essayant de récupérer les informations de trade-in.',
      getTradeInWidget: 'Une erreur est survenue en essayant de récupérer le widget de trade-in.',
      getUnlistedVehicleMakes: 'Une erreur est survenue en essayant de récupérer les marques du véhicule non répertorié',
      getUnlistedVehicleModels: 'Une erreur est survenue en essayant de récupérer les modèles du véhicule non répertorié',
      getUnlistedVehicleTrims: 'Une erreur est survenue en essayant de récupérer les trims du véhicule non répertorié',
      getVehicles: 'Une erreur est survenue en essayant de récupérer la liste de véhicules.',
      hasDeskingAccess: "Une erreur est survenue en essayant de récupérer les droits d'accès.",
      initializeCalculatedAdjustments: "Une erreur est survenue en essayant d'initialiser les ajustements calculés",
      linkDMSAccount: "Une erreur est survenue en essayant d'associer le compte DMS au contact.",
      loadContactSummary: 'Une erreur est survenue en essayant de récupérer les informations du contact.',
      loadDealJacketForm: 'Une erreur est survenue en essayant de télécharger un formulaire.',
      loadDMSAccountMatches: 'Une erreur est survenue en essayant de récupérer les comptes DMS associés.',
      loadDMSCustomer: 'Une erreur est survenue en essayant de récupérer les informations du client DMS.',
      loadIncentives: 'Une erreur est survenue en essayant de récupérer la liste des incitatifs',
      msdRoll: 'Une erreur est survenue en essayant de récupérer les informations de dépôt de sécurité',
      openPdfFile: "Une erreur est survenue en essayant d'ouvrir le fichier pdf à imprimer.",
      openUnlistedInDesking: "Une erreur est survenue en essayant d'ouvrir le véhicule non répertorié dans Desking",
      printForm: "Une erreur est survenue en essayant d'imprimer un formulaire.",
      resetValidation: 'Une erreur est survenue en essayant de réinitialiser les informations de validation',
      selectDealer: 'Une erreur est survenue en essayant de sélectionner le concessionnaire.',
      selectVehicle: 'Une erreur est survenue en essayant de sélectionner le véhicule.',
      selectVehicleMatch: 'Une erreur est survenue en essayant de choisir le véhicule similaire.',
      sendToSerti: 'Une erreur est survenue en essayant de transmettre la soumission à Serti.',
      setCustomerVisibility: 'Une erreur est survenue en essayant de partager la quote avec client(e)',
      setIncentive: "Une erreur est survenue en essayant de mettre à jour l'incitatif",
      setItemFlags: "Une erreur est survenue avec l'appel de setItemFlags",
      setPreferred: 'Une erreur est survenue en essayant de mettre la transaction comme préférée.',
      setQuoteFlags: "Une erreur est survenue avec l'appel de setQuoteFlags",
      submitRDR: 'Une erreur est survenue en essayant de soumettre RDR',
      tryLater: 'Oops! Une erreur est survenue, veuillez réessayer plus tard!',
      unexpected: 'Erreur inattendue.',
      unknown: 'Erreur inconnue.',
      updateCommission: 'Une erreur est survenue en essayant de changer les informations de la commission.',
      updateContact: 'Une erreur est survenue en essayant de actualiser ce contact.',
      updateCustomer: 'Une erreur est survenue en essayant de modifier le client.',
      updateFinancingDates: 'Une erreur est survenue en essayant de changer les dates.',
      updateFNIItemPlanCoverageParameters: "Une erreur est survenue en essayant de changer un paramètre de couverture d'un item FNI.",
      updateFNIItemPlanPricing: "Une erreur est survenue en essayant de changer un prix d'un item FNI.",
      updateForms: 'Une erreur est survenue en essayant de changer un formulaire.',
      updateQuote: 'Une erreur est survenue en essayant de changer la transaction.',
      uploadDealJacketForm: 'Une erreur est survenue en essayant de téléverser un formulaire.',
      validateAmountToPayRollChange: 'Une erreur est survenue en essayant de valider la modification du montant total à payer',
      validateDueAtDeliveryRollChange: 'Une erreur est survenue en essayant de valider la modification du montant payable à la livraison',
      validateFNIItem: 'Une erreur est survenue en essayant de choisir un item FNI.',
      validateItem: 'Une erreur est survenue en essayant de choisir un item.',
      validateModel: 'Une erreur est survenue en essayant de choisir le choix.',
      validatePaymentChange: 'Une erreur est survenue en essayant de valider la modification du paiement',
      validateProfitRollChange: 'Une erreur est survenue en essayant de valider la modification de profit',
      validation: "Votre modification n'a pas été approuvée.",
      modal: {
        hideDetails: 'Cacher les détails',
        showDetails: 'Afficher les détails',
        title: 'Erreur'
      },
      statusActions: {
        lead: "Une erreur est survenue lors de la mise à jour du statut de l'opportunité associée.",
        quote: 'Une erreur est survenue lors de la mise à jour du statut de la transaction.',
        vehicle: 'Une erreur est survenue lors de la mise à jour du statut du véhicule associé.'
      }
    },
    fieldRequired: 'Ce champ est requis',
    fieldsMissing: 'Certains champs requis sont vides',
    garage: {
      addQuote: 'Créer une soumission',
      addVehicle: 'Ajouter un véhicule',
      deletedMessage: 'Ce véhicule a été supprimé',
      delivered: 'Livré',
      expired: 'Expiré',
      headerTitle: 'Garage du client',
      onHold: 'Réservé',
      sold: 'Vendu',
      soldMessage: "Ce véhicule n'est plus disponible",
      vehicleResult: 'Véhicule'
    },
    lastUpdated: 'Modifié: ',
    matchesVehicles: {
      addedOption: 'Option ajoutée',
      addedOptions: 'Options ajoutées',
      backToSummary: 'Retour au sommaire',
      demo: 'Démo',
      extendedWarranty: 'Garantie prolongée',
      inTransit: 'En transit',
      inventory: "Aller à l'inventaire",
      newAdded: 'Nouveaux accessoires ajoutés par le concessionnaire',
      onHold: 'Réservé',
      onHoldMessage: 'Ce véhicule est réservé par une autre soumission',
      onOrder: 'En commande',
      optionsDetailModal: {
        code: 'Code',
        description: 'Description',
        noData: 'aucune option',
        price: 'Prix',
        seeDetails: 'Voir le détail des options',
        title: 'Détail des options'
      },
      perfect: 'Match parfait',
      perfect_plural: '{{count}} match parfaits!',
      perfectTitle: 'Bonne nouvelle! Nous avons un véhicule identique en inventaire!',
      perfectTitle_plural: 'Bonne nouvelle! Nous avons {{count}} véhicules identiques en inventaire!',
      select: 'Choisir',
      similar: 'Véhicule similaire prêt à être livré',
      similar_plural: 'Véhicules similaires prêts à être livrés',
      similarTitle: 'Nous avons un véhicule similaire',
      similarTitle_plural: 'Nous avons {{count}} véhicules similaires',
      sold: 'Vendu',
      wholesale: 'Wholesale'
    },
    offerDetails: "Détails de l'offre",
    paymentBar: {
      apr: 'Taux',
      cashDown: 'Acompte initial',
      cashDownShort: 'acompte',
      customTerm: 'Ajouter un terme',
      estimatedValue: 'Valeur estimée',
      frequency: 'Périodicité',
      kmPerYearPlan: 'Plan de km',
      lien: 'Lien sur échange',
      limitWarning: {
        and: 'et',
        msg: 'Le paiement doit se situer entre'
      },
      payment: 'Paiement désiré',
      rebateSub: 'Comprend un rabais total de',
      revised: 'Révisé',
      salesDeposit: 'Dépôt de vente',
      securityDeposit: 'Dépôt de sécurité',
      taxesIncluded: 'Taxes incluses',
      taxRebateLabel: 'Rabais inclut les taxes',
      term: 'Terme',
      title: 'Ajuster vos paiements',
      totalInterestToPay: "Total d'intérêts à payer",
      tradeIn: 'Échange estimé'
    },
    paymentFrequency: {
      12: 'au mois',
      24: 'bi-mensuel',
      26: 'aux 2 semaines',
      52: 'à la semaine'
    },
    purchaseMethod: {
      cash: 'Comptant',
      finance: 'Financement',
      lease: 'Location'
    },
    quoteStatus: {
      approval: 'En approbation',
      booked: 'Vendu en préparation',
      cancelled: 'Annulé',
      delivered: 'Livré',
      quote: 'En cours',
      sold: 'Vendu'
    },
    rate: {
      prefix: '',
      suffix: ' %'
    },
    selectionCategories: {
      accessory: 'Accessoire',
      accessoryPlural: 'Accessoires',
      option: 'Option',
      optionPlural: 'Options'
    },
    selectionConflicts: {
      apply: 'Appliquer les modifications',
      chooseOneOfAvailable: 'Choisir 1 des {{availableOptions}} configurations disponibles ci-dessous',
      configuration: 'Configuration',
      discard: 'Annuler',
      head: "Ce choix entre en conflit avec d'autres options",
      optionToAdd: 'Option à ajouter',
      optionToAdd_plural: 'Options à ajouter',
      optionToRemove: 'Option à supprimer',
      optionToRemove_plural: 'Options à supprimer',
      plusTaxes: '+ Taxes',
      title: 'Conflits',
      totalChange: 'Total des changements'
    },
    selectionOptions: {
      close: 'Fermer',
      description: 'Description',
      edit: 'Modifier',
      filterPlaceholder: 'Tapez ici pour filtrer les éléments',
      msrpAdjust: 'Ajust. PDSF: ',
      noItemsFound: 'Aucun résultat trouvé',
      optionsTitle: 'Modifier des {{category}}',
      preview: 'Aperçu',
      price: 'Prix de détail suggéré',
      selectedItems: 'Éléments sélectionnés',
      total: 'Prix de détail'
    },
    status: 'Statut',
    statusBar: {
      backToCustomerGarage: 'Retour au garage de {{customerName}}',
      backToGarage: 'Retour au garage',
      documentPrint: {
        preferredLanguage: 'Langue préférée'
      },
      manufacturerNotes: 'Notes du fabriquant',
      modal: {
        buttonApply: 'Ouvrir PDF',
        buttonClose: 'Annuler',
        field_one: '{{count}} champ',
        field_other: '{{count}} champs',
        select: 'Sélectionner',
        title: 'Remplir les champs'
      },
      offerSheet: "Feuille d'offre détaillée"
    },
    switchIsVisibleToCustomer: 'Partager avec client(e)',
    table: {
      result: 'résultat',
      results: 'résultats'
    },
    taxIn: 'Avec taxes',
    taxOut: 'Sans taxe',
    term: {
      and: 'à',
      editorTitle: 'Entrer nouveau terme',
      editValue: 'Éditer valeur',
      for: 'pour',
      helper: 'Entrer un nombre de mois de',
      months: 'mois',
      suffix: 'mois',
      termThousandSeparator: ' ',
      up: 'plus'
    },
    unlistedVehicle: {
      back: 'Retour',
      kms: 'KMs',
      make: 'Marque:',
      model: 'Modèle:',
      new: 'Neuf',
      openInDesking: 'Ouvrir dans Desking',
      sellingPrice: 'Prix de vente:',
      trim: 'Trim:',
      unlistedVehicle: 'Véhicule non répertorié',
      used: 'Occasion',
      year: 'Année:'
    },
    vehicle: {
      allFeatures: 'Toutes les fonctionnalités',
      bodyTrimCode: 'Model:',
      certified: 'Certifié',
      demo: 'Démo',
      downPayment: 'Acompte initial',
      editValue: 'Éditer valeur',
      extendedWarranty: 'Garantie prolongée',
      exterior: 'Extérieur',
      incentiveProgram: 'Programme incitatif',
      included: 'Inclus',
      inspected: 'Inspecté',
      interior: 'Intérieur',
      km: 'km',
      kmPerYearPlan: 'Plan de km',
      kmYear: 'km / année',
      make: 'Make',
      model: 'Model',
      new: 'Nouveau',
      newInventory: 'Nouvel Inv.',
      noDateTitle: 'Pas de date',
      noDescription: 'Pas de description',
      onDelivery: 'À la livraison',
      rebate: 'Rabais inclus',
      stockNumber: 'Inventaire #',
      title: {
        cash: 'Prix de vente',
        finance: 'Financement à',
        financeWithBalloon: 'Financement à',
        lease: 'Location à'
      },
      tradeIn: 'Échange estimé',
      trim: 'Version',
      used: 'Occasion',
      vehicleLocation: 'Ce véhicule est à',
      vehicleNotMapped: "Ce véhicule n'a pas encore été mappé sur une unité de stock",
      vinLabel: 'NIV:',
      wholesale: 'Vente en gros',
      year: 'Année',
      taxIncluded: 'Taxes incluses',
      taxNotIncluded: 'Taxes non incluses'
    },
    vehicleNotFoundText: 'Ce véhicule est {{status}}. Veuillez en choisir un autre.',
    vehicleStatus: {
      DELIVERED: 'Livré',
      FOR_SALE: 'Disponible',
      ON_HOLD: 'Réservé',
      SOLD: 'Vendu'
    }
  }
};