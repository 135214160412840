"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _uiLibrary = require("@sm360/ui-library");
var _commonsUtils = require("@sm360/commons-utils");
var _selectors = require("../../selectors");
var _actions = require("../../actions");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 */
function updateCustomer(_ref) {
  let {
    customer,
    onComplete,
    isCoApplicantSelected = false,
    isCoApplicantEditing = false
  } = _ref;
  return function* () {
    const {
      organizationId,
      organizationUnitId,
      location,
      lang
    } = yield (0, _effects.select)(_selectors.getConfig);
    const leadId = yield (0, _effects.select)(_selectors.getLeadId);
    const queryParams = {
      orgId: organizationId,
      orgUnitId: organizationUnitId,
      location,
      lang
    };
    const displayToast = (message, success) => {
      success ? _uiLibrary.Toast.toast.success(message, _commonsUtils.SHORT_TOAST_OPTIONS) : _uiLibrary.Toast.toast.error(message, _commonsUtils.SHORT_TOAST_OPTIONS);
      if (typeof onComplete === 'function') {
        onComplete();
      }
    };
    try {
      const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.updateCustomer, leadId, queryParams, customer);
      yield (0, _effects.put)(_actions.Creators.setCustomer(_objectSpread(_objectSpread({}, response), {}, {
        isCoApplicantSelected,
        isCoApplicantEditing
      })));
      customer.messages && displayToast(customer.messages.success, true);
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'updateCustomer'));
      customer.messages && displayToast(customer.messages.error, false);
    }
  }();
}
var _default = exports.default = updateCustomer;